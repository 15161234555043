<template>
  <app-user-template>
    <template slot="title">{{ $t('exchangeRates.add.createRate') }}</template>
    <template slot="content">
      <b-message v-if="success.hasSuccess" :type="getMessageType">
        <p v-show="success.hasSuccess" class="is-size-6">Cotización creada con exito</p>
      </b-message>
      <app-user-form @on-form-success="showSuccessMessages" @on-form-error="showErrorMessages"
        @on-form-processing="clearMessages"></app-user-form>
    </template>
  </app-user-template>
</template>
<script>
import CommonTemplate from '@/views/templates/CommonTemplate'
import { mapActions, mapGetters } from 'vuex'
import ExchangeRateForm from './ExchangeRateForm'

export default {
  name: 'exchangeRateCreate',
  components: {
    appUserTemplate: CommonTemplate,
    appUserForm: ExchangeRateForm
  },
  data() {
    return {
      error: { hasError: false, invalidFormError: false },
      success: { hasSuccess: false }
    }
  },
  computed: {
    ...mapGetters(['organizations', 'getRespError']),
    getMessageType() {
      if (this.error.hasError) {
        return 'is-danger'
      } else {
        return 'is-success'
      }
    }
  },
  methods: {
    ...mapActions(['getOrganizations']),
    clearMessages({ ...error }, success = { hasSuccess: false }) {
      this.error = error
      this.success = { ...success }
    },
    showSuccessMessages({ ...success }) {
      this.success = success
      this.$buefy.snackbar.open({ message: `La cotización se creo con éxito`, type: 'is-success' })
      const date = this.$route.query.date
      setTimeout(this.$router.push({ name: 'exchangeRate', query: { date } }), 1000)
    },
    showErrorMessages({ ...error }) {
      this.error = error
      if (error.invalidFormError) {
        this.$buefy.snackbar.open({
          message: `Error: El formulario es inválido, revise los campos`,
          type: 'is-danger',
          duration: 4000
        })
      } else if (error.hasError && !error.invalidFormError) {
        if (this.getRespError === 'exchange already exists') {
          this.$buefy.snackbar.open({ message: `No se puede cargar duplicado el tipo de cambio para el mismo día`, type: 'is-danger' })
        } else {
          this.$buefy.snackbar.open({ message: `¡Ups algo salio mal!`, type: 'is-danger' })
        }
      }
    }
  }
}
</script>
<style scoped></style>
