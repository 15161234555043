<template>
  <form id="exchangeRateCreate" novalidate @submit.prevent>
    <b-field label="Fecha">
      <div class="is-flex">
        <app-date-picker v-model='date' v-bind:nearbyselectable='true'></app-date-picker>
      </div>
    </b-field>
    <b-field grouped>
      <b-field label="Codigo de país" class="has-text-light" expanded>
        <b-select v-validate="'required'" :loading=requestExchangeRatesCountries v-model="selectedCountry"
          placeholder="Selecciona un País" required expanded icon="flag">
          <option v-for="(currency) in allCountries" :key="currency.code" :value="currency.id">
            {{ currency.code }}({{ currency.name }})</option>
        </b-select>
      </b-field>
      <b-field label="Codigo de país base" class="has-text-light" expanded>
        <b-select v-validate="'required'" :loading=requestExchangeRatesCountries v-model="selectedBaseCountry"
          placeholder="Selecciona un País" required expanded icon="flag">
          <option v-for="(currency) in allCountries" :key="currency.code" :value="currency.id">
            {{ currency.code }}({{ currency.name }})</option>
        </b-select>
      </b-field>
    </b-field>
    <b-field label="Cotización" class="has-text-light">
      <currency-input v-validate="'required'" v-model="rate" :options="{ currencyDisplay: 'hidden' }"
        :placeholder="'Ingrese una cotización'" :icon="'dollar-sign'"></currency-input>
    </b-field>
    <hr />
    <b-field grouped position="is-right">
      <p class="control">
        <router-link :to="{ name: 'exchangeRate', query: { date: dateFormated } }" class="button">
          <span>Cancelar</span>
        </router-link>
      </p>
      <p class="control">
        <a :class="{ 'is-loading': showLoader }" class="button is-accent" @click="onExchangeRateCreate">
          <span>Guardar</span>
        </a>
      </p>
    </b-field>
  </form>
</template>
<script>
import { formMixin } from '@/mixins/formMixin'
import { mapActions, mapGetters } from 'vuex'
import DatePicker from '@/common/widgets/date-picker/DatePicker'
import CurrencyInput from '@/common/widgets/currency-input/CurrencyInput'
import moment from 'moment'

export default {
  name: 'exchangeRateForm',
  components: {
    'app-date-picker': DatePicker,
    'currency-input': CurrencyInput
  },
  mixins: [formMixin],
  data() {
    return {
      allCountries: [],
      selectedCountry: undefined,
      selectedBaseCountry: 7,
      date: null,
      rate: ''
    }
  },
  computed: {
    ...mapGetters(['exchangeRatesCountries', 'requestExchangeRatesCountries', 'showLoader']),
    dateFormated() {
      return this.formatDate(this.date)
    }
  },
  watch: {
    date() {
      const stringDate = this.formatDate(this.date)
      this.$router.replace({ query: { date: stringDate } })
    }
  },
  mounted() {
    this.getExchangeRatesCountries().then(() => {
      this.allCountries = this.exchangeRatesCountries
    })
    const today = moment(new Date()).toDate()
    if (!this.$route.query.hasOwnProperty('date')) {
      this.date = today
    } else {
      const date = moment(this.$route.query.date, 'DD/MM/YYYY').toDate()
      if (date > today) {
        this.date = today
      } else {
        this.date = date
      }
    }
    const stringDate = this.formatDate(this.date)
    this.$router.replace({ query: { date: stringDate } })
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(value).format('DD/MM/YYYY')
      }
    },
    ...mapActions(['createExchangeRate', 'getExchangeRatesCountries']),
    onExchangeRateCreate() {
      let rate
      rate = {
        idCountry: this.selectedCountry,
        idCountryBase: this.selectedBaseCountry,
        date: moment(this.date).format('DD/MM/YYYY'),
        rate: this.rate.toString()
      }

      this.validateBeforeSubmit(
        [this.createExchangeRate],
        {
          rate
        },
        { enabled: false, routeName: 'exchangeRate' }
      )
    }
  }
}
</script>
<style scoped lang="scss"></style>
